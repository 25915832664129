<template>
  <v-col class="d-flex flex-column fill-height">
    <v-container class="d-flex flex-column justify-start align-center">
      <h2 class="mt-5 mb-10">Admin</h2>
    </v-container>
    <v-container class="d-flex flex-column align-center">
      <SubmitButton text="Download report" @click="getReport" />
      <!-- <SubmitButton text="Clear leaderboard" @click="clearLeaderboard" /> -->
    </v-container>
  </v-col>
</template>

<script>
import SubmitButton from "@/components/ui/buttons/SubmitButton";
import LeaderboardApi from "@/api/leaderboard.api";
export default {
  name: "Admin",
  components: { SubmitButton },
  methods: {
    async getReport() {
      const response = await LeaderboardApi.getReport();
      if (!response.error) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.csv");
        link.click();
      }
    },
    async clearLeaderboard() {
      const answer = confirm("Are you sure you want to clear the leaderboard?");
      if (answer) {
        const response = await LeaderboardApi.clearLeaderboard();
        if (response.error) {
          alert("Error: " + response.error);
        } else {
          alert(response.message);
        }
      }
    },
  },
};
</script>

<style scoped></style>
